<template>
    <div class="hero-container">
        <h1 class="title">谁更有智谋？</h1>
        <div class="hero-card" v-for="hero in heroes" :key="hero"
            :class="{ 'green-bg': selectedWinner === hero, 'red-bg': selectedWinner && selectedWinner !== hero }"
            @click="selectWinner(hero)">
            <img :src="'https://cdn-427studio.oss-cn-qingdao.aliyuncs.com/sanguo_header/11/' + hero + '.jpg'" :alt="hero" />
            <div class="hero-name">{{ hero }}</div>
        </div>
    </div>
</template>
  
<script>
import service from '@/utils/request';

export default {
    name: 'PK',
    data() {
        return {
            topic: '',
            heroes: [],
            selectedWinner: '',
            isSuccess: false
        }
    },
    created() {
        this.topic = this.$route.params.topic;
        this.reload();
    },
    methods: {
        reload() {
            service.get('/wikiapi/pk/pair', {
                params: {
                    type: this.topic
                }
            })
                .then(res => {
                    this.heroes = res.data;
                    this.selectedWinner = '';
                })
                .catch(err => {
                    console.error(err);
                });
        },
        selectWinner(winnerName) {
            this.selectedWinner = winnerName;
            const loser = this.heroes.find(hero => hero !== winnerName);
            service.post('/wikiapi/pk/pair', null, {
                params: {
                    type: this.topic,
                    winner: winnerName,
                    loser: loser
                }
            })
            .then(res => {
                this.reload();
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>
  
<style scoped>
.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    /* Center the title */
}

.hero-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow the cards to wrap to the next line */
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    /* Add some padding for smaller screens */
}

.hero-card {
    width: 260px;
    height: 300px;
    margin: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    transition: background-color 0.1s;
    /* Transition effect for background-color */
}

.hero-card.green-bg {
    background-color: #A5D6A7 !important;
    /* Lighter green */
    color: white !important;
}

.hero-card.red-bg {
    background-color: #EF9A9A !important;
    /* Lighter red */
    color: white !important;
}

.hero-card:hover {
    transform: scale(1.05);
}

.hero-card img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid white;
}

.hero-name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .hero-card {
        width: 200px;
        height: 240px;
    }

    .hero-card img {
        width: 180px;
        height: 180px;
    }

    .hero-name {
        font-size: 16px;
    }

    .title {
        font-size: 20px;
    }
}
</style>
